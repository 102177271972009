import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";

/** DEFAULT TOAST ANIMATION NB: ALL ANIMATIONS SHOULD HAVE THE TRIGGER NAME 'flyInOut' */
export let animationsPop = [
    trigger('flyInOut', [
        state('inactive', style({ opacity: 0 })),
        transition(
            'inactive => active',
            animate(
                '400ms ease-out',
                keyframes([
                    style({
                        opacity: 0,
                        bottom: '0px',
                        height: '0px',
                        'max-height': 0,
                        'max-width': 0,
                        'margin-top': 0,
                    }),
                    style({
                        opacity: 0.8,
                        bottom: '0px',
                    }),
                    style({
                        opacity: 1,
                        bottom: '0',
                        height: 'max-content',
                        'max-height': 'max-content',
                        position: 'relative',
                        'margin-top': '0px',
                        'max-width': '100%',
                    }),
                ]),
            ),
        ),
        state(
            'active',
            style({
                bottom: '0',
                height: 'max-content',
                'max-height': 'max-content',
                position: 'relative',
                'margin-top': '0px',
                'max-width': '100%',
               // 'padding-bottom' : thisPadding
            }),
        ),
        transition(
            'active => removed',
            animate(
                '400ms ease-out',
                keyframes([
                    style({
                        opacity: 1,
                        transform: 'translateY(0)'
                    }),
                    style({
                        opacity: 0,
                        transform: 'translateY(25%)'
                    }),
                ]),
            ),
        ),
    ])];

export let animationsSlideInOut = [
    trigger('flyInOut', [
        state('inactive', style({
            transform: 'translateY(20px) scale(1.1) rotateY(5deg)',
            opacity: 0,
            filter: 'blur(2px) saturate(50%)'
        })),
        transition('inactive => active',
            animate('.5s ease-in-out',
                keyframes([
                    style({
                        transform: 'translateY(20px) scale(1.1) rotateY(5deg)',
                        opacity: 0,
                        filter: 'blur(2px) saturate(50%)'
                    }),
                    style({
                        opacity: 0.8,
                        filter: 'blur(1px) saturate(75%)'
                    }),
                    style({
                        opacity: 1,
                        transform: 'translateY(0) scale(1) rotateY(0)',
                        filter: 'blur(0) saturate(100%)'
                    }),
                ])
            )
        ),
        state('active',
            style({
                transform: 'translateY(0) scale(1) rotateY(0)',
                opacity: 1,
                filter: 'blur(0) saturate(100%)'
            })),
        transition('active => removed',
            animate('.4s ease',
                keyframes([
                    style({
                        opacity: 1,
                        transform: 'translateX(0)  scale(1)',
                    }),
                    style({
                        opacity: 0,
                        transform: 'translateX(100%) scale(.7)',
                    }),
                ]))),
    ])
];

export let animationsOnHeight = [
    trigger('flyInOut', [
        state('inactive', style({
            opacity: 0,
        })),
        transition('inactive => active',
            animate('.4s ease',
                keyframes([
                    style({
                        height: '0px',
                        opacity: 0,
                    }),
                    style({
                        opacity: 0.8,
                    }),
                    style({
                        opacity: 1,
                        height: 'fit-content',
                    }),
                ])
            )
        ),
        state('active',
            style({
                height: 'fit-content',
                opacity: 1,
            })),
        transition('active => removed',
            animate('.4s ease',
                keyframes([
                    style({
                        opacity: 1,
                        transform: 'translateY(0)'
                    }),
                    style({
                        opacity: 0,
                        transform: 'translateY(25%)'
                    }),
                ])
            )
        ),
    ])
];
