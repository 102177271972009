import {Component, OnDestroy, OnInit,} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import {environment} from "../environments/environment";
import {PusherService} from "./_services/pusher.service";
import {AppService} from "./_services/app.service";
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from "angular-animations";
import * as Sentry from "@sentry/angular";


@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
  ],
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
      public appS: AppService,
  ) {
    this.appS.navigationS.setAppS(this.appS);
  }

  ngOnInit() {
    // SENTRY
    if (environment.production) {
      Sentry.init({
        dsn: environment.sentry_token,
        integrations: [
          Sentry.browserTracingIntegration(),
          // Registers the Replay integration,
          // which automatically captures Session Replays
          Sentry.replayIntegration(),
        ],
        tracePropagationTargets: [/^https:\/\/www\.sosf-manager\.com/, /^https:\/\/manager\.*/],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }

  ngOnDestroy() {}

  /* ---------------------------------------------------------------------------------------------------------------- */

}
