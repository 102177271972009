<div>
<!--<div [style.display]="state.value === 'inactive' ? 'none' : ''">-->
    <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-close">
        <i class="fad fa-times-circle"></i>
    </a>
    <div class="d-flex align-items-center">
        <div *ngIf="options['icon']" class="toastr-icon"><i [class]="options['icon']"></i></div>
        <div>
            <div *ngIf="title" class="toastr-title" [class]="options.titleClass" [attr.aria-label]="title">
                <div class="text">{{ title }}</div>
            </div>
            <div *ngIf="message && options.enableHtml"  class="toastr-message" role="alert" aria-live="polite" [class]="options.messageClass" [innerHTML]="message">
            </div>
            <div *ngIf="message && !options.enableHtml" class="toastr-message" role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
                {{ message }}
            </div>
<!--            <div class="buttons">-->
<!--                <button *ngFor="let button of buttons">{{ button.title }}</button>-->
<!--            </div>-->
        </div>
    </div>
</div>
<div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
