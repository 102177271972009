import {NgModule, APP_INITIALIZER, LOCALE_ID, ErrorHandler} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {CommonModule, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { AuthService } from "./component/auth";
import { ToastComponent } from "./layout/toast/toast.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {BlockUIModule} from "ng-block-ui";
import {ToastrModule} from "ngx-toastr";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatMenuModule} from "@angular/material/menu";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter} from "@angular/material-moment-adapter";
import {Router, RouterModule, UrlSerializer} from "@angular/router";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {CustomUrlSerializer} from "./_core/CustomUrlSerializer";
import {NgxGpAutocompleteModule} from "@angular-magic/ngx-gp-autocomplete";
import {QuillModule} from "ngx-quill";
import {Loader} from "@googlemaps/js-api-loader";
import {first} from "rxjs/operators";
import {LoaderModule} from "./layout/10_loader/loader.module";
import {NgApexchartsModule} from "ng-apexcharts";
import * as Sentry from "@sentry/angular";


function appInitializer(authService: AuthService) {
  return () => {
    return new Promise<void>((resolve, reject) => {
      authService.getUser().pipe(first()).subscribe().add(resolve);
    });
  };
}
registerLocaleData(localeFr);

@NgModule({ declarations: [
        AppComponent,
        ToastComponent
    ],
    exports: [
        MatMomentDateModule
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot(),
        HighlightModule,
        ClipboardModule,
        AppRoutingModule,
        NgbModule,
        // BlocUI
        BlockUIModule.forRoot(),
        // MATERIAL
        MatDialogModule,
        MatNativeDateModule,
        MatMenuModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDatepickerModule,
        // SELECT
        NgSelectModule,
        // WYSIWYG
        QuillModule.forRoot(),
        // TOASTR
        ToastrModule.forRoot({
            toastComponent: ToastComponent,
        }),
        // GOOGLE AUTO COMPLETE
        NgxGpAutocompleteModule,
        // PDF VIEWER
        NgxExtendedPdfViewerModule,
        // LOADER
        LoaderModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            deps: [AuthService, Sentry.TraceService],
            multi: true,
        },
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer
        },
        /* SENTRY */
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        /* GOOGLE AUTO COMPLETE */
        {
            provide: Loader,
            useValue: new Loader({
                apiKey: 'AIzaSyCaV103BDogCgkZZ1urBO9n2nPCBKvR-g8',
                libraries: ['places']
            })
        },
        /* LOCALE */
        {
            provide: LOCALE_ID,
            useValue: 'fr-FR',
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'fr-FR',
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
    constructor(trace: Sentry.TraceService) {}
}
